<template>
  <div>
    <vs-button
      color="primary"
      class="mt-4 mb-2"
      @click="goBackToVoting"
    >
      <i class="fa fa-undo-alt" /> Voltar para lista
    </vs-button>
    <vs-card class="mt-2 mb-3">
      <!-- Header -->
      <div class="d-flex">
        <div class="p-2">
          <h3 class="card-title">
            {{ $store.state.surveyStruct.surveyName }}
          </h3>

          <h5 class="mb-0 card-subtitle text-muted font-weight-normal">
            Editar votação
          </h5>
        </div>

        <div class="ml-auto p-2">
          <vs-button
            v-if="checkItemAccess(['Editar Projetos Digitais', 'Votacoes'])"
            class="ml-2 btn btn bg-transparent border-0 btn btn-primary text-light"
            :disabled="!Object.keys($store.state.surveyStruct).length"
            @click="saveSurvey()"
          >
            <i class="fas fa-save" />

            Salvar Votação
          </vs-button>
        </div>
      </div>

      <div class="mt-3">
        <div v-if="surveyId !== 0">
          <surveyStructure :survey-id="surveyId" />
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
/* Components */
import surveyStructure from '../../dashboard-components/survey-structure/SurveyStructure.vue';

export default {
  name: 'EditVoting',
  components: {
    surveyStructure
  },
  props: {
    surveyId: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    goBackToVoting() {
      this.$store.dispatch('set_isLoadingActive', false);
      this.$router.push({path: '/voting-operations'});
    },
    checkItemAccess(access) {
      if (!access) return true;
      else {
        let resp = false;

        access.forEach((i) => {
          if (this.$store.state.loginData.accessValues.includes(i)) {
            resp = true;
          }
        });

        return resp;
      }
    },
    saveSurvey() {
      this.$store.dispatch('set_isLoadingActive', true);
      this.$store
        .dispatch('saveSurveyStruct')
        .then(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.$vs.notify({
            title: 'Sucesso',
            text: 'Questionário salvo',
            color: 'success',
            icon: 'check_box',
          });
          this.goBackToVoting()
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.$vs.notify({
            title: 'Erro ao salvar',
            text: `Tente salvar manualmente: (${err})`,
            color: 'danger',
            icon: 'warning'
          })
        })
    }
  }
}
</script>
